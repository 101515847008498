import ScrollToTop from '../components/ScrollToTop';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

const AccountPlan = () => {
    const [navOpen] = useOutletContext();

    useEffect(() => {

        validate();

    }, []);
  
    

    return (
        <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
        <ScrollToTop/>
        <h1 id="contentstarts">My Account</h1>
        <div className='accountsContainer'>
            <div className="account-tabs">
            <div className="account-tab">
                <Link to="/xcl/accountprofile" className="at-label">Account Settings</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountpassword" className="at-label">Security & Password</Link>
            </div>
            <div className="account-tab active">
                <Link to="/xcl/accountplan" className="at-label selected">My Plan</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountbilling" className="at-label">Billing History</Link>
            </div>
            </div>
            <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>

                <div className="account-smaller-half-tile">
                    <div className="account-half-content">
                        <h4>My Plan</h4>
                    </div>
                </div>

                <div className="account-larger-half-tile">
                    <div className="account-half-content">
                        <h4>Plan Details</h4>
                    </div>
                </div>

            </div>

        </div>


        </div>
    );
};

export default AccountPlan;