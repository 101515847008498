import ScrollToTop from '../components/ScrollToTop';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';
import getUserData from '../xcl_modules/userData';
import getTokenData from '../xcl_modules/tokenData';
import iconAccountProfile from '../css/icons/icon-account-profile.png';
import iconEdit from '../css/icons/icon-edit.png';
import iconSave from '../css/icons/icon-save.png';
import iconHazard from '../css/icons/icon-hazard.png';
import iconArrowRight from '../css/icons/icon-arrowright-drk.png';
import ConfirmDeleteAccount from '../components/ConfirmDeleteAccount';
import PopupSuccess from '../components/popupAccountCancelSuccess';
import Switch from "react-switch";
import LengthIndicator from '../components/LengthIndicator';
import CountriesDropDown from '../components/CountriesDropDown';
import {countryDisplay} from '../xcl_modules/countries';
import Swal from 'sweetalert2';

const AccountProfile = () => {
  const [login, setLogin] = useState("");
  const [xclaccount, setXCLaccount] = useState("");
  const [xclaccountOriginal, setXCLaccountOriginal] = useState("");
  const [editmode, setEditmode] = useState(false);
  const [currentField, setCurrentField] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [successPop, setSuccessPop] = useState(false);
  const [confettiOff, setConfettiOff] = useState(false);

  const [navOpen] = useOutletContext();

  const nav = useNavigate();

  useEffect(() => {

    validate();
    const userData = getUserData();

    setLogin(userData.username);

    const getData = async () => {
      try {
          
        const response = await fetch(process.env.REACT_APP_BE+`/account/${userData.account}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json', 
              Authorization: `Bearer ${getTokenData().accessToken}`}
        });

        const result = await response.json();

        if (result.status === "success") {
          // clean the data
          /*console.log(result.data);
          Object.keys(result.data).forEach(key => {
            if (result.data[key] === null) {
              result.data[key] = "";
            }
          })
          console.log(result.data);*/
          setXCLaccount(result.data);
          setXCLaccountOriginal(result.data);
          setEditmode(false);
          setSubscriptionID(result.data.cb_subs_id);
          setConfettiOff((result.data.confettioff===0) ? false : true);
        }

      } catch {
        console.log("error getting data - see tools");
      }
    }

    getData();

  }, []);

  const confirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  }

  const deleteAccountConfirm = () => {
    setConfirmDeleteOpen(true);
  }

  const doDeleteAccount = async () => {
    // do delete account fetch
    console.log("delete it");
    /*try {
      
      const response = await fetch(process.env.REACT_APP_BE+`/cancelsubscription`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 
          Authorization: `Bearer ${getTokenData().accessToken}`},
        body: JSON.stringify( { cb_subs_id: subscriptionID } )
      });

      const result = await response.json();

      if (result.status === "success") {
        console.log(result.message);
        //console.log("deleted!");
        setSuccessPop(true);
        setConfirmDeleteOpen(false);
      }

    } catch {
        console.log("error getting data - see tools");
    }*/

  }
  const closeSuccessPop = () => {
    setSuccessPop(false);
    nav("/logout");
  }

  const handleChangeConfettiPref = async (checked, event, id) => {
    const confettiSetting = (confettiOff ? 0 : 1);
    setConfettiOff( confettiSetting );
    //console.log(confettiSetting);
    
    try {
      
      const response = await fetch(process.env.REACT_APP_BE+`/account`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 
          Authorization: `Bearer ${getTokenData().accessToken}`},
        body: JSON.stringify( { ID: xclaccount.ID, accountID: xclaccount.accountID, confettioff: confettiSetting } )
      });

      const result = await response.json();

      if (result.status === "success") {
        console.log(result.message);
      }

    } catch {
        console.log("error setting data - see tools");
    }

  };
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setCurrentField(name);
    setXCLaccount(values => ({...values, [name]: value}));
  }

  const editModeOn = () => {
    setEditmode(true);
  }

  const editModeOff = () => {
    setXCLaccount(xclaccountOriginal);
    setEditmode(false);
  }

  const saveEdits = async () => {
    // ok, so save this stuff
    setEditmode(false);

    try {
      
      const response = await fetch(process.env.REACT_APP_BE+`/account`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 
          Authorization: `Bearer ${getTokenData().accessToken}`},
        body: JSON.stringify( { ...xclaccount } )
      });

      const result = await response.json();

      if (result.status === "success") {
        //console.log(result.message);
        setXCLaccountOriginal(xclaccount);
        Swal.fire({
          position: "top",
          iconHtml:  '<div class="positoast"></div>',
          title: "Your changes have been saved!",
          showConfirmButton: false,
          timer: 1500,
          toast: true,
          timerProgressBar: true,
          showClass: {
              popup: 'toastCongratsOn'
          },
          hideClass: {
              popup: 'toastCongratsOff'
          }
        });
      }

    } catch {
        console.log("error setting data - see tools");
    }
  }

  const EditableValue = (props) => {
    let name = props.name;
    let label = props.label;
    let value = props.value;
    let length = props.length;

    const switchType = () => {
      switch(name) {
        case "country":
          return (
            <select name={name} defaultValue={value} onChange={handleChange}>
              <CountriesDropDown/>
            </select>
          )
        default:
          return (
            <>
              <input type="text" 
                value={value} 
                maxLength={length} 
                name={name} 
                placeholder="" 
                onChange={handleChange}
                autoFocus={(currentField === name) ? true : false}></input>
              <LengthIndicator max={length} threshold="0.75" length={(value) ? value.length : 0} />
            </>
          )
      }
    }

    const switchDisplay = () => {
      switch(name) {
        case "country":
          return (
            <>
              {countryDisplay(value)}
            </>            
          )
        default:
          return (
            <>
              {value}
            </>
          )
      }
    }
    return (
      <div className="account-row">
        <label htmlFor={name}>{label}</label>
        <div className="ap-display" style={(editmode) ? {display:"none"} : {display:"block"}}>
          { (value) ? switchDisplay(value) : "(empty)" }
        </div>
        <div className="ap-edit" style={(editmode) ? {display:"block"} : {display:"none"}}>
          { switchType() }
        </div>
      </div>
    )
  }

  return (
    <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
      <ScrollToTop/>
      <h1 id="contentstarts">My Account</h1>
      <div className='accountsContainer'>
        <ConfirmDeleteAccount 
          open={confirmDeleteOpen}
          subscriptionID={subscriptionID}
          onDelete={doDeleteAccount}
          onClose={confirmDeleteClose} />

        <PopupSuccess 
          open={successPop}
          element="Account"
          onClose={closeSuccessPop} />
        <div className="account-tabs">
          <div className="account-tab active">
            <Link to="/xcl/accountprofile" className="at-label selected">Account Settings</Link>
          </div>
          <div className="account-tab">
            <Link to="/xcl/accountpassword" className="at-label">Security & Password</Link>
          </div>
          <div className="account-tab">
            <Link to="/xcl/accountplan" className="at-label">My Plan</Link>
          </div>
          <div className="account-tab">
            <Link to="/xcl/accountbilling" className="at-label">Billing History</Link>
          </div>
        </div>
        <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>
          <div className="account-tile">
            <h4>
              <img src={iconAccountProfile} alt="Icon for your account profile"/> 
              Account Details
              <div className="account-controls">
              <input type="button" name='profileEdit' value="Edit Details " className="ap-action-button" onClick={editModeOn} style={(editmode) ? {display:"none"} : { display:"block", backgroundImage: `url('${iconEdit}')`}}></input>
              <input type="button" name='profileEdit' value="Cancel" onClick={editModeOff} style={(!editmode) ? {display:"none"} : { display:"inline" }}></input>
              <input type="button" name='profileEdit' value="Save Changes " className="ap-action-button" onClick={saveEdits} style={(!editmode) ? {display:"none"} : { display:"inline", backgroundImage: `url('${iconSave}')`}}></input>
              </div>
            </h4>
            
            <div className="account-row" style={{ width: "100%"}}>
              <div className="ap-label">Username:</div>
              <div className="ap-value">{login}</div>
              
            </div>
            <EditableValue name="firstname" label="First Name" value={xclaccount.firstname || ""} length="120" />
            <EditableValue name="lastname" label="Last Name" value={xclaccount.lastname || ""} length="120" />
            <EditableValue name="email" label="Account Email" value={xclaccount.email || ""} length="200" />
            <EditableValue name="company" label="Company" value={xclaccount.company || ""} length="100" />
            <EditableValue name="address1" label="Street Address" value={xclaccount.address1 || ""} length="100" />
            <EditableValue name="address2" label="Address 2" value={xclaccount.address2 || ""} length="100" />
            <EditableValue name="city" label="City" value={xclaccount.city || ""} length="60" />
            <EditableValue name="postalcode" label="Postal Code" value={xclaccount.postalcode || ""} length="10" />
            <EditableValue name="country" label="Country" value={xclaccount.country || ""} length="45" />
            <EditableValue name="state" label="State" value={xclaccount.state || ""} length="45" />
            <EditableValue name="phone" label="Phone" value={xclaccount.phone || ""} length="45" />
            <EditableValue name="mobile" label="Mobile" value={xclaccount.mobile || ""} length="45" />
            
            <div className="account-row" style={{ width: "100%"}}>
              <Switch 
                id="confettiOff" 
                onChange={handleChangeConfettiPref} 
                checked={(confettiOff ? false : true)} 
                offColor='#858993' 
                onColor='#00606B' 
                aria-label='Turn off confetti explosions' 
                className='visSwitch'/>
              <label htmlFor='confettiOff' 
                style={{ display:"inline-block", width:"calc(100% - 90px)", verticalAlign: "super", marginLeft: "10px", marginTop: "0px" }}>
                  Confetti explosions
              </label>
            </div>

          </div>
          <div className='acCalloutBlock delete'>
            <div className='acCalloutIcon' style={{ backgroundImage: `url(${iconHazard})`}}></div>
            <h4>Cancel Account
            <Link className='acCalloutBtn delete' style={{ backgroundImage: `url(${iconArrowRight})`}} onClick={deleteAccountConfirm}>Cancel My Account</Link>

            </h4>
          
            <p>The following will occur at the end of your current subscription term.</p>
            <ul>
              <li>Permanently delete all account information</li>
              <li>Permanently delete all links and branding material</li>
              <li>Your subscription will be cancelled</li>
            </ul>
            <p className='emphasized'>Once you cancel your account, there is no going back. Please be certain.</p>
          </div>
          <ConfirmDeleteAccount 
          open={confirmDeleteOpen}
          subscriptionID={subscriptionID}
          onDelete={doDeleteAccount}
          onClose={confirmDeleteClose} />

          <PopupSuccess 
          open={successPop}
          element="Account"
          onClose={closeSuccessPop} />
        </div>

      </div>


    </div>
  );
};

export default AccountProfile;