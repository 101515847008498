import ScrollToTop from '../components/ScrollToTop';
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import validate from '../xcl_modules/validate';
import { useEffect, useState } from 'react';

const AccountBilling = () => {

    const [navOpen] = useOutletContext();

    useEffect(() => {

        validate();

    }, []);
  
    return (
        <div className={'contentOuter ' + ((navOpen) ? 'openNav' : '') }>
        <ScrollToTop/>
        <h1 id="contentstarts">My Account</h1>
        <div className='accountsContainer'>
            <div className="account-tabs">
            <div className="account-tab">
                <Link to="/xcl/accountprofile" className="at-label">Account Settings</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountpassword" className="at-label">Security & Password</Link>
            </div>
            <div className="account-tab">
                <Link to="/xcl/accountplan" className="at-label">My Plan</Link>
            </div>
            <div className="account-tab active">
                <Link to="/xcl/accountbilling" className="at-label selected">Billing History</Link>
            </div>
            </div>
            <div className="account-tiles" style={{ borderRadius: "0px 8px 8px 8px" }}>

                <div className="account-tile">
                    <h4>Billing History and Details</h4>
                </div>

                <div className="account-tile">
                    &lt; History list here &gt;
                </div>

            </div>

        </div>

        </div>
    );
};

export default AccountBilling;